import * as React from 'react';
import {IChannelCatalogItem} from 'models/channelCatalog';

export const useChannelCatalogListDuration = (
  listDuration: number,
  movedInto: IChannelCatalogItem[] = [],
  movedOut: IChannelCatalogItem[] = [],
): {duration: number} => {
  const duration = React.useMemo(() => {
    const totalMovedIntoDuration = movedInto.reduce((acc, item) => acc + item.duration, 0);
    const totalMovedOutDuration = movedOut.reduce((acc, item) => acc + item.duration, 0);
    return listDuration + totalMovedIntoDuration - totalMovedOutDuration;
  }, [listDuration, movedInto, movedOut]);

  return {
    duration,
  };
};
