import {getUseMemoryListApi} from 'views/programming/channel/edit/catalog/hooks/getUseMemoryListApi';
import {useMemoryListProvider} from 'views/programming/channel/contexts/MemoryLibraryProvider';
import {useFindLibraryDurationQuery} from 'features/channelCatalog/channelCatalogApi';

export const useMemoryLibraryApi = getUseMemoryListApi(
  useMemoryListProvider,
  useFindLibraryDurationQuery as (...args: any[]) => {
    data: {duration: number} | null;
    isError: boolean;
    error: any;
    isFetching: boolean;
    refetch: () => void;
  },
);
