import {get} from 'lodash-es';
import {ChannelCatalogItemWithState} from 'views/programming/channel/contexts/getMemoryListProvider';

const getSplittedSort = (sort: string) => {
  const splitedSort = sort.split(':');
  return splitedSort;
};

export const sortList = (queue: ChannelCatalogItemWithState[], sort: string): ChannelCatalogItemWithState[] => {
  const tempQueue = [...queue];
  const [sortKey, sortOrder] = getSplittedSort(sort);

  const comparator = (key: string) => (a: ChannelCatalogItemWithState, b: ChannelCatalogItemWithState) => {
    const aValue = get(a, key);
    const bValue = get(b, key);

    if (aValue > bValue) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    if (aValue < bValue) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    return 0;
  };

  return tempQueue.sort(comparator(sortKey));
};
