import * as React from 'react';
import {cloneDeep, throttle, maxBy} from 'lodash-es';
import {IDateRange, isValEmpty, useCancellableSummon} from '@pluto-tv/assemble';
import {IChannel} from 'models/channels';
import {IActiveRegion} from 'models/activeRegions';
import {IElasticSearchRes, IListPayload, IListQuery} from 'models/generic';
import {useUserRegions} from 'helpers/useUserRegions';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {ICatalogItem, IGapOrOverlap} from 'views/programming/channel/utils/models';
import {ITimeline} from 'features/channelTimelines/channelTimelinesApi';

interface IChannelSearch extends Partial<Omit<IChannel, 'published' | 'activeRegion'>>, IListQuery {
  // Author needs to be simplified from an object to a string
  author?: string;
  // Certain fields are expected to be a string representation of a boolean in the API
  isUsed?: string;
  promotional?: string;
  liveBroadcast?: string;
  published?: string;
  // Active Region needs to be an array
  activeRegion?: string[];
  // regionFilter -> territories
  territories?: string[];
}

export interface IChannelMetaSearch {
  uploadDate?: IDateRange;
  duration?: IChannel;
  window?: 'day' | 'week' | 'month';
}

interface IUseChannelSearch {
  channel?: IListPayload<IChannel>;
  isError: boolean;
  isFetching: boolean;
  rowsPerPage: 25 | 50 | 75 | 100;
  setRowsPerPage;
  page: number;
  setPage;
  sort: string;
  setSort;
  clearSearch: (doSearch?: boolean) => void;
  search: (searchModel?: Partial<IChannel>, searchMeta?: IChannelMetaSearch, sort?: string) => void;
}

const transformReq = (
  model: Partial<IChannel>,
  page: number,
  perPage: 25 | 50 | 75 | 100,
  sort: string,
  searchMeta?: IChannelMetaSearch,
  activeRegions?: IActiveRegion[],
): IChannelSearch => {
  const searchObj = cloneDeep(model) as IChannelSearch;

  if (model.activeRegion) {
    searchObj.activeRegion = [model.activeRegion];
  } else {
    searchObj.activeRegion = activeRegions?.map(ar => ar.code.toLowerCase());
  }

  return {
    ...searchObj,
    sort,
    limit: perPage,
    offset: page * perPage,
  };

  if (searchMeta) {
    alert('searchMeta');
  }
};

const defaultSearch: Partial<IChannel> = {
  provider: 'jwplatform',
};

const defaultSort = 'createdAt:desc';

export const useChannelSearch = (): IUseChannelSearch => {
  const [isFetching, setIsFetching] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [channel, setChannel] = React.useState<IListPayload<IChannel>>();

  const [rowsPerPage, setRowsPerPage] = React.useState<25 | 50 | 75 | 100>(25);
  const [page, setPage] = React.useState<number>(0);
  const [sort, setSort] = React.useState<string>(defaultSort);

  const {activeRegions} = useUserRegions();
  const [summon] = useCancellableSummon();

  const clearSearch = async (doSearch = false): Promise<void> => {
    setPage(0);
    setSort(defaultSort);

    if (doSearch) {
      await search({}, {}, defaultSort);
    }
  };

  const search = throttle(
    async (searchModel?: Partial<IChannel>, searchMeta?: IChannelMetaSearch, customSort?: string) => {
      setIsFetching(true);

      const mySort = customSort ? customSort : sort;

      let cleanedModel: Partial<IChannel> = {};

      if (!searchModel || isValEmpty(searchModel)) {
        cleanedModel = cloneDeep(defaultSearch);
      } else {
        Object.keys(searchModel).forEach(key => {
          const fieldName: keyof IChannel = key as keyof IChannel;

          if (!isValEmpty(searchModel[fieldName])) {
            cleanedModel[fieldName] = cloneDeep(searchModel[fieldName]) as any;
          }
        });
      }

      const fullReq = transformReq(cleanedModel, page, rowsPerPage, mySort, searchMeta, activeRegions);

      try {
        const res = await summon.post<IChannelSearch, IElasticSearchRes<IChannel>>('channels', fullReq);
        const data = res.hits.hits.map(hit => hit._source);

        setChannel({
          data,
          metadata: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            totalCount: res.hits.total,
          },
        });
      } catch (e) {
        setIsError(true);
      } finally {
        setIsFetching(false);
      }
    },
    150,
    {leading: false, trailing: true},
  );

  return {
    channel,
    isError,
    isFetching,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    sort,
    setSort,
    search,
    clearSearch,
  };
};

export const errorMsgTransform = (error: string): string => {
  try {
    const errorMsg = error
      .replaceAll('ValidationError: ', '')
      .split(',')
      .map(x => {
        const [, error, field] = x.split(': ');
        return `${error}${field ? ': ' + field : ''}`;
      })
      .join(', ')
      // specific message handles
      .replace('sourceId key should not be empty', 'CFAAS Integration requires a valid SourceId Key')
      .replace('sourceId value should not be empty', 'CFAAS Integration requires a valid SourceId Value');
    return errorMsg;
  } catch (e) {
    return error;
  }
};

export type StateColorType = 'success' | 'error' | 'warning' | 'info' | 'focus' | '';
export interface IStateMsgList {
  color: StateColorType;
  icon: StateColorType;
  label: string;
}
export interface IItemState {
  state: StateColorType;
  stateMsgList: IStateMsgList[];
}

export const getItemState = (item: IChannelCatalogItem | ICatalogItem): IItemState => {
  const stateResult = {state: '' as StateColorType, stateMsgList: [] as IStateMsgList[]};

  if (!item.id) return stateResult;

  // errors
  if (!item.allClipsReady) {
    stateResult.state = 'error';
    stateResult.stateMsgList.push({
      icon: 'warning',
      color: 'error',
      label: 'At least one clip in the episode is not ready.',
    });
  }

  if (item.hasClipWithEmptySources) {
    stateResult.state = 'error';
    stateResult.stateMsgList.push({
      icon: 'warning',
      color: 'error',
      label: 'Episode has clips with empty sources.',
    });
  }

  // warnings
  if (!item.isAvailableNow || !item.hasLinearAvailabilityWindows) {
    stateResult.state = !stateResult.state ? 'warning' : stateResult.state;
    stateResult.stateMsgList.push({
      icon: 'warning',
      color: 'warning',
      label: 'Episode has no availability, or is being used outside of its availability window.',
    });
  }

  if (!item.published) {
    stateResult.state = !stateResult.state ? 'warning' : stateResult.state;
    stateResult.stateMsgList.push({
      icon: 'warning',
      color: 'warning',
      label: 'Episode is not published.',
    });
  }

  if (!item.hasAdPods) {
    stateResult.state = !stateResult.state ? 'warning' : stateResult.state;
    stateResult.stateMsgList.push({
      icon: 'warning',
      color: 'warning',
      label: 'Episode has no adPods.',
    });
  }

  if (item.hasOwnProperty('hasMixedProviderWith') ? item.hasMixedProviderWith : !item.hasUniqueProvider) {
    stateResult.state = !stateResult.state ? 'warning' : stateResult.state;
    stateResult.stateMsgList.push({
      icon: 'warning',
      color: 'warning',
      label: 'Episode uses clips with mixed providers.',
    });
  }

  return stateResult;
};

export const getOverlapsOnCalendar = (timelines: ITimeline[]): IGapOrOverlap[] => {
  return timelines
    ?.sort((a, b) => a.start.getTime() - b.start.getTime())
    .reduce((state: IGapOrOverlap[], currentValue: ITimeline, _currentIndex: number, timelines: ITimeline[]) => {
      // currentValue start or stop are in between any of the timelines
      const startEndCoincidence: {id: string; date: Date; duration: number}[] | undefined = [];

      timelines.forEach(t => {
        if (t.id !== currentValue.id && !state?.find(s => s.id === t.id)) {
          if (currentValue.start.getTime() >= t.start.getTime() && currentValue.start.getTime() < t.stop.getTime()) {
            startEndCoincidence?.push({
              id: currentValue.id,
              date: t.start,
              duration:
                t.stop.getTime() > currentValue.stop.getTime()
                  ? currentValue.stop.getTime() - t.start.getTime()
                  : t.stop.getTime() - t.start.getTime(),
            });
          } else if (
            currentValue.stop.getTime() > t.start.getTime() &&
            currentValue.stop.getTime() <= t.stop.getTime()
          ) {
            startEndCoincidence?.push({
              id: currentValue.id,
              date: t.start,
              duration: currentValue.stop.getTime() - t.start.getTime(),
            });
          } else if (
            currentValue.start.getTime() <= t.start.getTime() &&
            currentValue.stop.getTime() >= t.stop.getTime()
          ) {
            startEndCoincidence.push({
              id: currentValue.id,
              date: t.start,
              duration: t.stop.getTime() - t.start.getTime(),
            });
          }
        }
      });

      return startEndCoincidence.length ? [...state, ...startEndCoincidence] : state;
    }, []);
};

export const getPushingFutureOnCalendar = (
  endDate: number,
  timelines: ITimeline[],
  pristinelTimelines: ITimeline[],
): number => {
  // filter the timelines that are not recurrence
  const noRecurrenceTimelines = timelines.filter(x => !x.isRecurrenceEvent);
  // now we get the last timeline
  const lastTimeline = maxBy(noRecurrenceTimelines, x => x.stop);
  const lastTimelinePristine = maxBy(pristinelTimelines, x => x.stop);

  if (lastTimeline && lastTimelinePristine && lastTimelinePristine.stop.getTime() > endDate) {
    const diffTime =
      lastTimeline && lastTimeline.stop?.getTime() > endDate
        ? lastTimeline.stop?.getTime() - lastTimelinePristine.stop?.getTime()
        : 0;
    return diffTime;
  }

  // -1 because endDate is considering a millisecond before the end of the day
  return lastTimeline ? lastTimeline.stop?.getTime() - endDate - 1 : 0;
};

export const isChannelGracenoteReady = (model: Partial<IChannel>): boolean => {
  if (!model.tmsid) return false;
  if (!model.gracenoteIntegration) return false;
  if (model.gracenoteIntegration?.enabled !== false) return false;
  if (!model.gracenoteIntegration?.dynamicClip) return false;

  return true;
};
