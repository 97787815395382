import * as React from 'react';
import {useMemoryListProvider as useMemoryQueueApiProvider} from 'views/programming/channel/contexts/MemoryQueueProvider';
import {ITimeframe, createTimelinesWorker, getTimelineState} from 'views/programming/channel/edit/program/utilities';

import {ITimeline} from 'features/channelTimelines/channelTimelinesApi';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {IStateMsgList} from 'views/programming/channel/utils';

export interface IChannelProgrammingItemWithState extends IChannelCatalogItem {
  state: string;
  stateMsgList: IStateMsgList[];
}

interface IUseProgrammingQueueReturn {
  queueDataWithState: IChannelProgrammingItemWithState[];
  createNewTimelinesAutoFill: (
    timeframes: ITimeframe[],
    lastDraftTimeline: ITimeline | undefined,
  ) => Promise<ITimeline[]>;
  findEpisodeInQueue: (item: any) => IChannelProgrammingItemWithState | undefined;
}

export const useProgrammingQueue = (): IUseProgrammingQueueReturn => {
  const {data: queueData} = useMemoryQueueApiProvider();

  const queueDataWithState: IChannelProgrammingItemWithState[] = React.useMemo(
    () => queueData?.data?.map(data => ({...data, ...getTimelineState(data)})) || [],
    [queueData?.data],
  );

  const findEpisodeInQueue = React.useCallback(
    (item: any) => {
      const episode = queueDataWithState.find(ep => ep.id === item.episode);
      return episode;
    },
    [queueDataWithState],
  );

  const createNewTimelinesAutoFill = React.useCallback(
    (timeframes: ITimeframe[], lastDraftTimeline: ITimeline | undefined) =>
      createTimelinesWorker(timeframes, lastDraftTimeline, queueDataWithState || []),
    [queueDataWithState],
  );

  return {queueDataWithState, findEpisodeInQueue, createNewTimelinesAutoFill};
};
