import * as React from 'react';
import {IListPayload} from 'models/generic';
import {ChannelCatalogItemWithState} from 'views/programming/channel/contexts/getMemoryListProvider';
import {IChannelCatalogQuery} from 'models/channelCatalog';
import {IUseMemoryListApi} from 'views/programming/channel/edit/catalog/hooks/getUseMemoryListApi';

export type ChannelCatalogListReturn = {
  listItems: ChannelCatalogItemWithState[];
  setListItems: React.Dispatch<React.SetStateAction<ChannelCatalogItemWithState[]>>;
  totalItems: number;
  isSuccess: boolean;
  duration: number;
  isError: boolean;
  isLazyLoading: boolean;
  isLoading: boolean;
  fetch: (searchParams?: Partial<IChannelCatalogQuery>, options?: {pristine: boolean}) => void;
  refetch: () => void;
  sort: string;
  refetchDuration: () => void;
  handleLazyLoad: () => void;
  handleFiltering: (formModel: Partial<IChannelCatalogQuery>) => void;
  handleSorting: (sort: string) => void;
  shuffle: () => void;
  moveAlong: (fromIndex: number[], toIndex: number) => void;
  addItems: (index: number, items: ChannelCatalogItemWithState[]) => void;
  removeItems: (items: ChannelCatalogItemWithState[]) => void;
  movedToList: ChannelCatalogItemWithState[];
};

const getSortString = (sort = '') => {
  return sort.split('&')[0];
};

const getSplittedSort = (sort: string) => {
  const splitedSort = sort.split(':');
  return splitedSort;
};

export const getUseChannelCatalogList = (
  useMemorylistApi: (channelId: string, skipDuration?: boolean) => IUseMemoryListApi,
): ((
  channelId: string,
  isSearchActive?: boolean,
  placement?: 'top' | 'alternate',
  skipDuration?: boolean,
) => ChannelCatalogListReturn) => {
  const useChannelCatalogList = (
    channelId: string,
    isSearchActive?: boolean,
    placement: 'top' | 'alternate' = 'alternate',
    skipDuration = false,
  ): ChannelCatalogListReturn => {
    const {
      data,
      isError,
      isLoading,
      isSuccess,
      fetchList,
      refetch: refetchList,
      refetchDuration,
      shuffle,
      addItems: addListItems,
      removeItemsById: removeListItems,
      moveAlong: moveAlongList,
      defaultParams,
    } = useMemorylistApi(channelId || '', skipDuration);

    const [movedToList, setMovedToList] = React.useState<ChannelCatalogItemWithState[]>([]);
    const [listItems, setListItems] = React.useState<ChannelCatalogItemWithState[]>([]);
    const [params, setParams] = React.useState<Partial<IChannelCatalogQuery>>(defaultParams);
    const [loadingFirstPage, setLoadingFirstPage] = React.useState<boolean>(true);
    const [duration, setDuration] = React.useState<number>(0);

    // this ref stores the previous search params to be used in lazy loading
    const searchParamsRef = React.useRef<Partial<IChannelCatalogQuery>>(defaultParams);
    const dataRef = React.useRef<IListPayload<ChannelCatalogItemWithState>>();

    React.useEffect(() => {
      fetchList(params);
    }, [fetchList, params]);

    React.useEffect(() => {
      if (data) {
        dataRef.current = data;
        setListItems(prevItems => (data.metadata.offset === 0 ? data.data : [...prevItems, ...data.data]));
      }
    }, [data]);

    React.useEffect(() => {
      setDuration(data?.metadata?.duration || 0);
    }, [data?.metadata?.duration]);

    const refetch = React.useCallback(() => {
      setLoadingFirstPage(true);
      refetchList();
    }, [refetchList]);

    const fetch = React.useCallback(
      (searchParams?: Partial<IChannelCatalogQuery>) => {
        setLoadingFirstPage(true);
        searchParamsRef.current = (searchParams as IChannelCatalogQuery) || {};
        setParams({...defaultParams, ...searchParams});
      },
      [defaultParams],
    );

    const handleLazyLoad = React.useCallback(() => {
      if (!channelId || !dataRef.current?.data) return;

      const {data, metadata} = dataRef.current || {data: [], metadata: {}};

      const sortArray = metadata.sort ? metadata.sort.split('&') : undefined;

      if (data.length === metadata.limit) {
        setLoadingFirstPage(false);

        setParams({
          ...defaultParams,
          ...searchParamsRef.current,
          offset: listItems.length || defaultParams.limit,
          sort: sortArray as `${string}:${string}`[],
        });
      }
    }, [channelId, defaultParams, listItems.length]);

    const handleSorting = React.useCallback(
      (sortField: string) => {
        if (!channelId || listItems.length < 2) return;
        setListItems([]);
        setLoadingFirstPage(true);

        const {sort} = dataRef.current?.metadata || {sort: 'order:asc'};

        const [prevSortField, prevSortDirection] = getSplittedSort(
          getSortString(sort || (defaultParams?.sort!.join('&') as string)),
        );
        const sortDirection = prevSortField === sortField && prevSortDirection === 'asc' ? 'dsc' : 'asc';

        setParams({
          ...defaultParams,
          ...searchParamsRef.current,
          sort: [`${sortField}:${sortDirection}`],
        });
      },
      [channelId, defaultParams, listItems.length],
    );

    const addItems = React.useCallback(
      (index: number, items: ChannelCatalogItemWithState[]) => {
        setListItems(addListItems(placement === 'alternate' ? index : 0, items));
        setMovedToList(prevItems => [...prevItems, ...items]);
      },
      [addListItems, placement],
    );

    const removeItems = React.useCallback(
      (items: ChannelCatalogItemWithState[]) => {
        if (!isSearchActive) {
          setListItems(removeListItems(items));
        } else {
          removeListItems(items);
          setListItems(prevItems => prevItems.filter(item => !items.some(i => i.id === item.id)));
        }
        setMovedToList(prevItems => prevItems.filter(item => !items.some(i => i.id === item.id)));
      },
      [isSearchActive, removeListItems],
    );

    const moveAlong = React.useCallback(
      (fromIndex: number[], toIndex: number): void => {
        setListItems(moveAlongList(fromIndex, toIndex));
      },
      [moveAlongList],
    );

    const handleFiltering = React.useCallback(
      (formModel: Partial<IChannelCatalogQuery>) => {
        setParams({
          ...defaultParams,
          ...searchParamsRef.current,
          ...formModel,
        });
      },
      [defaultParams],
    );

    return {
      listItems,
      isSuccess,
      setListItems,
      duration,
      sort: getSortString(data?.metadata.sort as string),
      totalItems: listItems.length,
      isError,
      isLazyLoading: isLoading && !loadingFirstPage,
      isLoading: isLoading && loadingFirstPage,
      handleLazyLoad,
      addItems,
      removeItems,
      moveAlong,
      handleSorting,
      refetchDuration,
      fetch,
      shuffle,
      refetch,
      handleFiltering,
      movedToList,
    };
  };

  return useChannelCatalogList;
};
