import * as React from 'react';
import {isEqual} from 'lodash-es';
import {IChannelCatalogQuery} from 'models/channelCatalog';
import {IListPayload} from 'models/generic';
import {sortList} from 'views/programming/channel/edit/catalog/utils/catalogListSortHelper';
import {filterList} from 'views/programming/channel/edit/catalog/utils/catalogListFilterHelper';
import {shuffle} from 'views/programming/channel/edit/catalog/utils/listShuffle';
import {reorderList} from 'helpers/dragAndDrop';
import {
  ChannelCatalogItemWithState,
  IMemoryListContext,
} from 'views/programming/channel/contexts/getMemoryListProvider';

export interface IUseMemoryListApi {
  data: IListPayload<ChannelCatalogItemWithState> | null;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  fetchList: (searchParams?: Partial<IChannelCatalogQuery>) => void;
  refetchDuration: () => void;
  setList: (newList: ChannelCatalogItemWithState[]) => void;
  shuffle: () => void;
  addItems: (index: number, items: ChannelCatalogItemWithState[]) => ChannelCatalogItemWithState[];
  moveAlong: (fromIndex: number[], toIndex: number) => ChannelCatalogItemWithState[];
  removeItemsById: (items: ChannelCatalogItemWithState[]) => ChannelCatalogItemWithState[];
  refetch: () => void;
  defaultParams: Partial<IChannelCatalogQuery>;
}

const listReducer = (
  state: IListPayload<ChannelCatalogItemWithState> | null,
  action: IListPayload<ChannelCatalogItemWithState>,
): IListPayload<ChannelCatalogItemWithState> => {
  return {
    ...state,
    ...action,
  };
};

const shouldFilterTable = (searchParams?: Partial<IChannelCatalogQuery>) => {
  const {name, genre, updatedAt, rating, season, tags, genres} = searchParams || {};
  return Boolean(name || genre || updatedAt || rating || season || tags || genres);
};

const isSearchParamsEqual = (
  searchParams: Partial<IChannelCatalogQuery>,
  prevSearchParams: Partial<IChannelCatalogQuery>,
): boolean => {
  const keys = Object.keys(searchParams) as (keyof IChannelCatalogQuery)[];
  return keys.every(key => isEqual(searchParams[key], prevSearchParams[key]));
};

export const getUseMemoryListApi = (
  useMemoryProvider: () => IMemoryListContext,
  useFindDurationQuery: (...args: any[]) => {
    data: {duration: number} | null;
    isError: boolean;
    error: any;
    isFetching: boolean;
    refetch: () => void;
  },
): ((channelId: string, skipDuration?: boolean) => IUseMemoryListApi) => {
  const useMemoryListApi = (channelId: string, skipDuration = false): IUseMemoryListApi => {
    const {
      getMemoryList,
      setMemoryList,
      setListModelChange,
      data,
      isError,
      isFetching,
      error,
      refetch,
      isSuccess,
      defaultParams,
    } = useMemoryProvider();

    const [isMemoryLoading, setIsMemoryLoading] = React.useState(false);
    const filteredListLength = React.useRef<number | null>(null);

    const searchParamsRef = React.useRef<Partial<IChannelCatalogQuery>>(defaultParams as Partial<IChannelCatalogQuery>);

    // this send data to the hook that has the list to the table
    const [listData, setListData] = React.useReducer<
      React.Reducer<IListPayload<ChannelCatalogItemWithState> | null, IListPayload<ChannelCatalogItemWithState>>
    >(listReducer, null);

    const {
      data: durationData,
      isError: isDurationError,
      error: durationError,
      isFetching: isFetchingDuration,
      refetch: refetchDuration,
    } = useFindDurationQuery(
      {channelId, ...defaultParams},
      {
        skip: !channelId || skipDuration,
      },
    );

    React.useEffect(() => {
      if (listData?.data || (error && (error as {status: number})?.status === 404)) {
        setIsMemoryLoading(false);
      }
    }, [error, listData]);

    const shuffleList = React.useCallback(() => {
      setMemoryList(shuffle(getMemoryList()));
      setListModelChange();
    }, [getMemoryList, setListModelChange, setMemoryList]);

    const removeItemsById = React.useCallback(
      (items: ChannelCatalogItemWithState[]): ChannelCatalogItemWithState[] => {
        const newList = getMemoryList().filter(item => !items.some(i => i.id === item.id));
        setMemoryList(newList);
        setListModelChange();
        return getMemoryList();
      },
      [getMemoryList, setListModelChange, setMemoryList],
    );

    const addItems = React.useCallback(
      (index: number, items: ChannelCatalogItemWithState[]): ChannelCatalogItemWithState[] => {
        const newList = [...getMemoryList().slice(0, index), ...items, ...getMemoryList().slice(index)];
        setMemoryList(newList, {removeDuplicates: true});
        setListModelChange();
        return getMemoryList();
      },
      [getMemoryList, setMemoryList, setListModelChange],
    );

    const moveAlong = React.useCallback(
      (fromIndex: number[], toIndex: number): ChannelCatalogItemWithState[] => {
        setMemoryList(reorderList(fromIndex, toIndex, getMemoryList()));
        setListModelChange();
        return getMemoryList();
      },
      [getMemoryList, setMemoryList, setListModelChange],
    );

    const fetchList = React.useCallback(
      (searchParams?: Partial<IChannelCatalogQuery>) => {
        setIsMemoryLoading(true);
        // used when filter is active, this list is reset when the user clear the form
        let tempList: ChannelCatalogItemWithState[] | null = null;

        const limit = searchParams?.limit || defaultParams.limit;
        const offset = searchParams?.offset || defaultParams.offset || 0;

        const getTotalCount = () => {
          if (error && (error as {status: number})?.status === 404) return 0;
          return Array.isArray(tempList) ? filteredListLength.current || 0 : data?.metadata.totalCount || 0;
        };

        const getTotalDuration = () => {
          if (shouldFilterTable(searchParams)) {
            return (tempList || []).reduce((acc, item) => acc + item.duration, 0);
          }
          return isDurationError && (durationError as {status: number})?.status !== 404
            ? 0
            : durationData?.duration || 0;
        };

        if (shouldFilterTable(searchParams)) {
          tempList = filterList(getMemoryList(), searchParams) as unknown as ChannelCatalogItemWithState[];
        }

        if (searchParams?.sort && searchParams.sort.length > 0) {
          if (tempList) {
            tempList = sortList(tempList as any, searchParams.sort[0]) as unknown as ChannelCatalogItemWithState[];
          } else {
            setMemoryList(sortList(getMemoryList(), searchParams.sort[0]));
            if (
              searchParamsRef.current.sort !== undefined &&
              searchParamsRef.current.sort[0] !== searchParams.sort[0]
            ) {
              setListModelChange();
            }
          }
        }

        const slicedData = (tempList || getMemoryList()).slice(offset, limit + offset) || [];
        if (tempList) {
          if (
            !isSearchParamsEqual(
              searchParamsRef.current,
              (searchParams || defaultParams) as Partial<IChannelCatalogQuery>,
            )
          ) {
            filteredListLength.current = tempList.length;
          }
        } else {
          filteredListLength.current = null;
        }
        searchParamsRef.current = (searchParams || defaultParams) as Partial<IChannelCatalogQuery>;

        setListData({
          data: slicedData,
          metadata: {
            sort: (searchParams?.sort || defaultParams.sort!).join('&'),
            offset: offset,
            duration: !skipDuration ? getTotalDuration() : 0,
            limit,
            totalCount: getTotalCount(),
          },
        });
      },
      [
        defaultParams,
        getMemoryList,
        skipDuration,
        error,
        data?.metadata.totalCount,
        isDurationError,
        durationError,
        durationData?.duration,
        setMemoryList,
        setListModelChange,
      ],
    );

    return {
      data: listData,
      isSuccess,
      addItems,
      refetchDuration,
      shuffle: shuffleList,
      setList: setMemoryList,
      isError: isError && (error as {status: number})?.status !== 404,
      isLoading: isFetching || isMemoryLoading || isFetchingDuration,
      fetchList,
      moveAlong,
      removeItemsById,
      refetch,
      defaultParams,
    };
  };

  return useMemoryListApi;
};
