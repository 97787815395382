import {IChannelCatalogParams} from 'models/channelCatalog';
import {getUseChannelCatalogList} from 'views/programming/channel/edit/catalog/hooks/getUseChannelCatalogList';
import {useMemoryLibraryApi} from 'views/programming/channel/edit/catalog/hooks/useMemoryLibraryApi';

export const defaultParams: IChannelCatalogParams = {
  sort: ['series.name:asc', 'season:asc', 'number:asc'],
  offset: 0,
  limit: 1000,
};

export const useChannelCatalogLibrary = getUseChannelCatalogList(useMemoryLibraryApi);
