import * as React from 'react';
import {uniqBy} from 'lodash-es';
import {IChannelCatalogItem, IChannelCatalogParams} from 'models/channelCatalog';
import {getItemState, IItemState} from 'views/programming/channel/utils/index';
import {IListPayload} from 'models/generic';

export type ChannelCatalogItemWithState = IChannelCatalogItem & IItemState;

export interface IMemoryListContext {
  setMemoryList: (list: ChannelCatalogItemWithState[], options?: {removeDuplicates: boolean}) => void;
  getMemoryList(): ChannelCatalogItemWithState[];
  listModel?: {episode: string; order: number}[];
  setListModelChange: () => void;
  defaultParams: Pick<IChannelCatalogParams, 'offset' | 'limit' | 'sort'>;
  data?: IListPayload<IChannelCatalogItem>;
  isError: boolean;
  isFetching: boolean;
  error: unknown;
  refetch: () => void;
  isSuccess: boolean;
}

interface IMemoryListProviderProps {
  channelId: string;
  defaultSort: `${string}:${string}`[];
  children: React.ReactNode;
}

export const defaultParams = {offset: 0, limit: 50000};

export const getMemoryListProvider = (
  useFindQuery: (...args: any[]) => {
    currentData?: IListPayload<IChannelCatalogItem>;
    isError: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    error?: unknown;
    refetch: () => void;
  },
): {
  MemoryListContext: React.Context<IMemoryListContext | null>;
  MemoryListProvider: React.FC<IMemoryListProviderProps>;
  useMemoryListProvider: () => IMemoryListContext;
} => {
  const MemoryListContext = React.createContext<IMemoryListContext | null>(null);

  const MemoryListProvider = ({channelId, defaultSort, children}: IMemoryListProviderProps): React.ReactElement => {
    const params: IChannelCatalogParams = React.useMemo(() => ({...defaultParams, sort: defaultSort}), [defaultSort]);
    const {
      currentData: data,
      isSuccess,
      isError,
      isFetching,
      error,
      refetch,
    } = useFindQuery({channelId, ...params}, {skip: !channelId, refetchOnMountOrArgChange: true});

    const getMemoryList = React.useCallback(() => memoryListRef.current, []);

    const setMemoryList = React.useCallback(
      (newList: ChannelCatalogItemWithState[], options?: {removeDuplicates: boolean}) => {
        memoryListRef.current = options?.removeDuplicates ? uniqBy(newList, 'id') : newList;
      },
      [],
    );

    const setListModelChange = React.useCallback(() => {
      setListModel(
        memoryListRef.current.map((item, index) => ({
          episode: item.id,
          order: index + 1,
        })),
      );
    }, []);

    React.useLayoutEffect(() => {
      if (data) {
        const itemsWithState = data?.data?.map(item => ({...item, ...getItemState(item)})) || [];
        setMemoryList(itemsWithState);
      }
    }, [data, setMemoryList]);

    const memoryListRef = React.useRef<ChannelCatalogItemWithState[]>([]);

    const [listModel, setListModel] = React.useState<{episode: string; order: number}[]>();

    const value = React.useMemo(
      () => ({
        getMemoryList,
        setMemoryList,
        setListModelChange,
        defaultParams: params,
        listModel, // that is what it goes to the put request
        data,
        isError,
        isSuccess,
        isFetching,
        error,
        refetch,
      }),
      [
        getMemoryList,
        setMemoryList,
        setListModelChange,
        params,
        listModel,
        data,
        isError,
        isSuccess,
        isFetching,
        error,
        refetch,
      ],
    );

    return <MemoryListContext.Provider value={value}>{children}</MemoryListContext.Provider>;
  };

  const useMemoryListProvider = (): IMemoryListContext => {
    const context = React.useContext(MemoryListContext);

    if (!context) {
      throw new Error('useMemoryListProvider must be used within a MemoryListProvider');
    }

    return context;
  };

  return {
    MemoryListContext,
    MemoryListProvider,
    useMemoryListProvider,
  };
};
