import {getUseMemoryListApi} from 'views/programming/channel/edit/catalog/hooks/getUseMemoryListApi';
import {useMemoryListProvider} from 'views/programming/channel/contexts/MemoryQueueProvider';
import {useFindQueueDurationQuery} from 'features/channelQueue/channelQueueApi';

export const useMemoryQueueApi = getUseMemoryListApi(
  useMemoryListProvider,
  useFindQueueDurationQuery as (...args: any[]) => {
    data: {duration: number} | null;
    isError: boolean;
    error: any;
    isFetching: boolean;
    refetch: () => void;
  },
);
